<script setup lang="ts">
// types
import type { Nullable, Student } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// vuetify
import { useDisplay, useTheme } from 'vuetify'

// config
import generateMenuItems from '@/config/navigation'

// stores
import { useCourseMaterialsStore } from '@/store/course-materials'
import { useGlobalStore } from '@/store/global'

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * nuxt runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * route & router
 * ==================================================================
 */
const route = useRoute()
const router = useRouter()

/**
 * vuetify
 * ==================================================================
 */
const { lgAndUp } = useDisplay()
const vuetifyTheme = useTheme()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const drawer = ref(lgAndUp)
const renderError = ref(false)
const showAvailabilityDialog = ref(false)

/**
 * stores
 * ==================================================================
 */
const courseMaterialsStore = useCourseMaterialsStore()
const { courseMaterials } = storeToRefs(courseMaterialsStore)

const enrollmentStore = useEnrollmentStore()

const globalStore = useGlobalStore()
const { isLoading, menuItems } = storeToRefs(globalStore)

const noticeStore = useNoticeStore()
const {
  headsUpNotice,
  newNotice,
  showHeadsUpNoticeSnackbar,
  showNewNoticeSnackbar
} = storeToRefs(noticeStore)

const organizationStore = useOrganizationStore()
const { organizationInfo: company } = storeToRefs(organizationStore)

const sessionStore = useSessionStore()
const { liveSessions } = storeToRefs(sessionStore)

const studyAreaStore = useStudyAreaStore()

const subjectStore = useSubjectStore()

/**
 * composables
 * ==================================================================
 */
const { isMobile } = useDevice()
const { doHandleError } = useErrorHandler()

/**
 * computed
 * ==================================================================
 */
// actor
const actor = computed(() => {
  return $actor.core.actor.value as Student
})

const actorId = computed(() => {
  return actor.value?.id || null
})

// auth
const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

// styles
const containerClasses = computed(() => {
  return isLoggedIn.value
    ? scheduleSessionsStep.value > 1
      ? 'container mx-auto pt-0'
      : 'container mx-auto pt-13'
    : 'container pa-0 d-flex align-center justify-center'
})
const mainStyles = computed(() => {
  return {
    background: `${vuetifyTheme.current.value.colors.backgroundgrey}`,
    height: '100%'
  }
})

// enrollment
const enrollmentId = computed(() => {
  return Number(route.params.enrollmentId)
})

// sessions
const liveSession = computed(() => {
  if (!liveSessions.value.length) {
    return
  }
  return liveSessions.value[0]
})

// schedule sessions steps
const scheduleSessionsStep = computed(() => {
  return $actor.core.storage.getState<number>('scheduleSessionsStep')
})

const scheduleSessionsHeading = computed(() => {
  return $actor.core.storage.getState<string>('scheduleSessionsHeading')
})

const steps = computed(() => {
  return [
    {
      routePath: '/schedule/tutor',
      stepNumber: 1,
      stepTitle: 'Choose a tutor'
    },
    {
      routePath: `/schedule/tutor/${tutorId.value}/enrollment/${enrollmentId.value}`,
      stepNumber: 2,
      stepTitle: 'Pick my times'
    },
    {
      stepNumber: 3,
      routePath: `/schedule/tutor/${tutorId.value}/enrollment/${enrollmentId.value}/confirm`,
      stepTitle: 'Finish'
    }
  ]
})

// tutor
const tutorId = computed(() => {
  return Number(route.params.tutorId)
})

/**
 * methods
 * ==================================================================
 */
function doNavBack () {
  switch (scheduleSessionsStep.value) {
    case 2:
      return router.replace({
        path: steps.value[0].routePath
      })
    case 3:
      return router.replace({
        path: steps.value[1].routePath
      })
  }
}

// navigation drawer
function doToggleDrawer () {
  drawer.value = !drawer.value
}

// snackbars
function toggleSnackbarHeadsUpNotice (val: boolean) {
  showHeadsUpNoticeSnackbar.value = val
}

function toggleSnackbarNewNotice (val: boolean) {
  showNewNoticeSnackbar.value = val
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  isLoading.value = true
})

onMounted(async () => {
  // set loading false to hide skeleton-loaders
  isLoading.value = false

  // fetch course materials
  try {
    await courseMaterialsStore.index()
  } catch (error) {
    doHandleError(error as FetchError)
  }

  // set menu items
  menuItems.value = generateMenuItems(courseMaterials.value)
})

/**
 * data fetching
 * ==================================================================
 */
await useLazyAsyncData('schedule-sessions-layout',
  async () => {
    try {
      await Promise.all([
        subjectStore.index({
          params: {
            grade: actor.value?.grade
          }
        }),
        enrollmentStore.index({
          params: {
            active: true
          }
        }),
        studyAreaStore.index({
          params: {
            studentId: actorId.value,
            archived: false,
            include: 'subject'
          }
        }),
        sessionStore.index({
          params: {
            attending: true,
            include: 'supervisor,zoom_meeting,place,course,subject',
            language: 'en',
            per: 10,
            schedule: 'live'
          }
        },
        'live'
        )
      ])
    } catch (errorResponse) {
      doHandleError(errorResponse as FetchError)
    }
  }
)

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <div
      v-if="isLoggedIn"
      style="height: 100%;"
    >
      <div
        v-if="isLoading"
        class="d-flex"
        style="height: 100%;"
      >
        <div
          v-if="!isMobile"
          class="d-flex flex-column"
          style="width: 256px; border-right: 0.75px solid #E4E4E4;"
        >
          <v-skeleton-loader
            v-for="i in 6"
            :key="`loader-${i}`"
            type="list-item"
            width="256px"
            height="56px"
            style="border-right: 0.75px solid #E4E4E4; border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
        <div
          class="d-flex flex-column"
          style="width: 100%;"
        >
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="56px"
            style="border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            width="100%"
            height="calc(100vh - 126px)"
            style="border-radius: 0px;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="70px"
            style="border-top: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
      </div>
      <template v-if="!isLoading">
        <lazy-r-navigation-drawer
          :actor="actor"
          :app-stage="config.public.appStage"
          :app-version="config.public.appVersion"
          :drawer="drawer"
          :menu-items="menuItems"
          :settings="config"
          @toggle-drawer="doToggleDrawer"
        />
        <client-only>
          <LazyAppBar @toggle-drawer="doToggleDrawer" />
        </client-only>
        <lazy-r-snackbar-heads-up-notice
          v-if="headsUpNotice"
          :show="showHeadsUpNoticeSnackbar"
          :notice="headsUpNotice"
          @toggle-heads-up-snackbar="toggleSnackbarHeadsUpNotice"
        />
        <lazy-r-snackbar-new-notice
          v-if="newNotice"
          :show="showNewNoticeSnackbar"
          :notice="newNotice"
          @toggle-new-notice-snackbar="toggleSnackbarNewNotice"
        />
        <v-main
          :style="mainStyles"
          class="mb-4"
        >
          <LazyBannerLiveSession
            v-if="liveSession"
            :session="liveSession"
          />
          <v-container
            :class="containerClasses"
            :fill-height="!isLoggedIn"
          >
            <div v-if="!renderError">
              <lazy-r-button-back
                v-if="scheduleSessionsStep > 1"
                class="btn-back ps-0 pe-2 mt-2"
                @do-nav-back="doNavBack"
              />
              <header class="d-flex flex-column flex-sm-row">
                <h1
                  v-if="scheduleSessionsHeading"
                  class="text-h5 font-weight-bold mb-4"
                >
                  {{ scheduleSessionsHeading }}
                </h1>
                <v-spacer />
                <v-btn
                  class="mb-4"
                  size="small"
                  @click="showAvailabilityDialog = true"
                >
                  Update Availability
                </v-btn>
              </header>
              <LazyStepper
                v-if="scheduleSessionsStep > 1"
                :steps="steps"
                :value="scheduleSessionsStep"
              />
            </div>
            <slot v-if="!isLoading && !renderError" />
            <r-error-display
              v-else-if="renderError && capturedError"
              :error="capturedError"
            />
          </v-container>
          <DialogAvailabilityCalendar
            :show="showAvailabilityDialog"
            :student="actor"
            @toggle-dialog="showAvailabilityDialog = $event"
          />
        </v-main>
        <client-only>
          <r-footer
            :app="isLoggedIn"
            :company="company"
            :logged-in="isLoggedIn"
            :settings="config"
          />
        </client-only>
      </template>
    </div>
  </v-app>
</template>

<style scoped>
.btn-back {
  right: 0.375rem;
}

@media (min-width: 960px) {
  .container {
    width: 80vw;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .container {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}
</style>
